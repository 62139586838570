import { type Draft } from 'immer';

import { type LabelFrameSlice, type LabelFrameSliceValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: LabelFrameSliceValues = {
  frame: 0,
  framesInCache: [],
  jobCategoriesToMids: {
    ANNOTATION_JOB_COUNTER: {},
    ANNOTATION_NAMES_JOB: {},
  },
  prevFrame: 0,
  selectedFrames: undefined,
  sessionMuteSetting: true,
  videoParams: {
    firstFrameTime: 0,
    framesPlayedPerSecond: 0,
    isMetadataLegacy: false,
    numberOfFrames: 0,
    totalDuration: 0,
  },
  visibleInterval: {
    left: 0,
    right: 30,
    scrollOffset: 0,
  },
};

const log = createLogger('labelFrame');

export const createLabelFrameSlice: ZustandSlice<LabelFrameSlice> = set => ({
  ...initialState,
  incrementJobCategoryAnnotationCount: payload =>
    set(
      state => {
        const { category, increment = 1, jobName } = payload;
        const { ANNOTATION_JOB_COUNTER } = state.labelFrame.jobCategoriesToMids;
        const count = ANNOTATION_JOB_COUNTER?.[jobName]?.[category] ?? 0;

        state.labelFrame.jobCategoriesToMids.ANNOTATION_JOB_COUNTER[jobName] = {
          ...state.labelFrame.jobCategoriesToMids.ANNOTATION_JOB_COUNTER[jobName],
          [category]: count + increment,
        };
      },
      false,
      log('incrementJobCategoryAnnotationCount', payload),
    ),
  initJobCategoriesToMids: payload =>
    set(
      state => {
        const { ANNOTATION_JOB_COUNTER, ANNOTATION_NAMES_JOB } = payload;

        state.labelFrame.jobCategoriesToMids = {
          ANNOTATION_JOB_COUNTER,
          ANNOTATION_NAMES_JOB,
        };
      },
      false,
      log('initJobCategoriesToMids', payload),
    ),
  initialize: payload =>
    set(
      state => {
        const keysToIgnore = ['jobCategoriesToMids', 'sessionMuteSetting', 'videoParams'];

        Object.entries(initialState).forEach(([key, value]) => {
          const keyInitialState = key as keyof typeof initialState;
          if (keysToIgnore.includes(keyInitialState)) {
            return;
          }

          (state.labelFrame[keyInitialState] as unknown) = value;
        });

        state.labelFrame.videoParams.framesPlayedPerSecond = payload.framesPlayedPerSecond;
        state.labelFrame.videoParams.isMetadataLegacy = !('numberOfFrames' in payload);
        if (payload.numberOfFrames)
          state.labelFrame.videoParams.numberOfFrames = payload.numberOfFrames;
        state.labelFrame.videoParams.firstFrameTime =
          payload.startTime !== undefined ? payload.startTime : 0;
      },
      false,
      log('initialize', payload),
    ),
  resetFrame: () =>
    set(
      state => {
        state.labelFrame.frame = initialState.frame;
        state.labelFrame.prevFrame = initialState.prevFrame;
      },
      false,
      log('resetFrame'),
    ),
  resetSelectedFrames: () =>
    set(
      state => {
        state.labelFrame.selectedFrames = initialState.selectedFrames;
      },
      false,
      log('resetSelectedFrames'),
    ),
  setAnnotationName: payload =>
    set(
      state => {
        const { mid, name } = payload;
        state.labelFrame.jobCategoriesToMids.ANNOTATION_NAMES_JOB[mid] = name;
      },
      false,
      log('setAnnotationName', payload),
    ),
  setFramesInCache: payload =>
    set(
      state => {
        state.labelFrame.framesInCache = payload.map(String);
      },
      false,
      log('setFramesInCache', payload),
    ),
  setJobCategoriesToMids: payload =>
    set(
      state => {
        const { mid, jobName, category, name } = payload;
        const counter = state.labelFrame.jobCategoriesToMids.ANNOTATION_JOB_COUNTER;
        const currentCounter = counter?.[jobName]?.[category];
        state.labelFrame.jobCategoriesToMids = {
          ...state.labelFrame.jobCategoriesToMids,
          ANNOTATION_JOB_COUNTER: {
            ...state.labelFrame.jobCategoriesToMids.ANNOTATION_JOB_COUNTER,
            [jobName]: {
              ...counter[jobName],
              [category]: currentCounter ? currentCounter + 1 : 1,
            },
          },
          ANNOTATION_NAMES_JOB: {
            ...state.labelFrame.jobCategoriesToMids.ANNOTATION_NAMES_JOB,
            [mid]: `${name} ${currentCounter ? currentCounter + 1 : 1}`,
          },
        };
      },
      false,
      log('setJobCategoriesToMids', payload),
    ),
  setSelectedFrames: payload =>
    set(
      state => {
        const { frame, selectedFrames } = payload;
        state.labelFrame.frame = frame;
        state.labelFrame.prevFrame = frame;
        if (selectedFrames) {
          state.labelFrame.selectedFrames = selectedFrames;
        }
      },
      false,
      log('setSelectedFrames', payload),
    ),
  updateField: payload =>
    set(
      state => {
        const { path, value } = payload;
        (state.labelFrame as Draft<LabelFrameSliceValues>)[path] = value;
      },
      false,
      log('updateField', payload),
    ),
});

export * from './types';
