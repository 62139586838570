/* eslint-disable no-underscore-dangle */
import { OFREPWebProvider } from '@openfeature/ofrep-web-provider';
import { OpenFeature, InMemoryProvider } from '@openfeature/react-sdk';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isUsingLocalConnect, isUsingOpenIdConnect } from '@/auth0';
import { isUsingFlipt } from '@/config';
import { isOrganizationError } from '@/redux/organization';
import { userHasCompletedProfile } from '@/redux/user/selectors';

import { DEVELOPMENT_FLAGS } from './developmentFlags';

export const useOpenFeatureProvider = (
  email: string | null,
  userId: string | null,
  organizationId: string | null,
) => {
  const [loading, setLoading] = useState(true);
  const isProfileCompleted = useSelector(userHasCompletedProfile);
  const hasNoOrganization = useSelector(isOrganizationError);

  useEffect(() => {
    const setProvider = async () => {
      const shouldWaitOrganizationId = !!email && isProfileCompleted && !hasNoOrganization;

      const shouldBypassCheck = (isUsingOpenIdConnect || isUsingLocalConnect) && !email && !userId;

      if (shouldBypassCheck || (email && userId && (!shouldWaitOrganizationId || organizationId))) {
        try {
          OpenFeature.setContext({
            email,
            userId,
            ...(organizationId && { organizationId }),
          });
          if (!isUsingFlipt) {
            /* InMemoryProvider does not support typing for flags keys
            https://github.com/open-feature/js-sdk/issues/967
            */
            OpenFeature.setProvider(new InMemoryProvider(DEVELOPMENT_FLAGS as any));
          } else {
            const apiUrl = window._env_?.FEATURE_FLAG__API_URL;
            const apiNamespace = window._env_?.FEATURE_FLAG__API_NAMESPACE;

            await OpenFeature.setProviderAndWait(
              new OFREPWebProvider({
                baseUrl: apiUrl,
                headers: [['x-flipt-namespace', apiNamespace]],
              }),
            );
          }
        } finally {
          setLoading(false);
        }
      }
    };

    setProvider();
  }, [email, hasNoOrganization, isProfileCompleted, organizationId, userId]);

  return loading;
};
