import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Right } from '@/__generated__/globalTypes';
import { organizationId } from '@/redux/organization/selectors';
import { userRights } from '@/redux/user/selectors';
import { useStore } from '@/zustand';

export function useSendLicenseLimitNotification() {
  const userRightsValue = useSelector(userRights);
  const organizationIdValue = useSelector(organizationId);

  useEffect(() => {
    const shouldSeeLicenseValue = userRightsValue.includes(Right.SEE_LICENSE_BANNER);
    if (!organizationIdValue) return;
    if (shouldSeeLicenseValue) {
      useStore.getState().topBar.setLicenseLimitBannerOpen(true);
    }
  }, [organizationIdValue, userRightsValue]);
}
