import { css } from '@emotion/css';

import { LICENCE_LIMITATION_BANNER_ZINDEX } from '@/constants/zIndexes';

export const classes = {
  closeButton: css`
    margin-left: var(--space-4);
  `,
  container: css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-beta-accent-6);
    color: var(--color-omega-accent-0);
    padding: 0 var(--space-4);
    cursor: default;
    z-index: ${LICENCE_LIMITATION_BANNER_ZINDEX};
  `,
  text: css`
    flex: 1;
    text-align: center;

    & a {
      color: inherit;
      text-decoration: underline;
    }
  `,
};
