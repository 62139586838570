import React from 'react';

import { BannerLimitReached } from './BannerLimitReached/BannerLimitReached';
import GenericLeftBar from './GenericLeftBar';
import GenericRightBar from './GenericRightBar';

import BaseTopBar from '../../components/BaseTopBar';

type PropTypes = {
  children?: React.ReactNode;
};

const TopBar = (props: PropTypes): JSX.Element => {
  const { children } = props;

  return (
    <>
      <BaseTopBar leftSideComponent={<GenericLeftBar />} rightSideComponent={<GenericRightBar />}>
        {children}
      </BaseTopBar>

      <BannerLimitReached />
    </>
  );
};

TopBar.defaultProps = {
  children: null,
};

export default TopBar;
