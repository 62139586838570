import { type ApolloClient } from '@apollo/client';
import {
  type Annotation,
  type EntityAnnotation,
  type EntityRelation,
  type FrameJsonResponse,
  type GeoSpatialContent,
  type JobAnnotation,
  type Jobs,
  type JsonContent,
  type JsonResponse,
  MachineLearningTask,
  type ObjectAnnotation,
  type ObjectRelation,
} from '@kili-technology/types';
import { generateHash } from '@kili-technology/utilities';
import cuid from 'cuid';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';

import { projectInputType } from '@/redux/selectors';

import { type Asset, InputType, type Label, LabelType } from '../../__generated__/globalTypes';
import {
  type CreateUploadBucketSignedUrlsQuery,
  type CreateUploadBucketSignedUrlsQueryVariables,
} from '../../graphql/asset/__generated__/queries.graphql';
import { GQL_CREATE_UPLOAD_BUCKET_SIGNED_URLS } from '../../graphql/asset/queries';
import { projectID as projectProjectID } from '../project/selectors';
import { type State } from '../types';

export const APPEND_MANY_TO_DATASET_MAX_BATCH_SIZE_IN_MB = 100;

const NUMBER_OF_BYTES_IN_ONE_MB = 1_000_000;

type UploadDataResponse = {
  assetId?: string;
  url: string;
};

export const uploadDataViaREST = async (
  data: File[],
  getState: () => State,
  client: ApolloClient<unknown>,
): Promise<UploadDataResponse[]> => {
  const state = getState();
  const projectID = projectProjectID(state);
  const inputType = projectInputType(state);
  const filesToUrlAndIdMap = new Map<string, { assetId: string; blobPath: string }>();
  data.forEach(file => {
    const assetId = cuid();
    const blobPath =
      inputType === InputType.GEOSPATIAL
        ? `projects/${projectID}/assets/${assetId}/content.tif`
        : `projects/${projectID}/assets/${assetId}/content`;
    filesToUrlAndIdMap.set(file.name, {
      assetId,
      blobPath,
    });
  });
  const filePaths = [...filesToUrlAndIdMap.values()].map(entry => entry.blobPath);
  const {
    data: { urls },
  } = await client.query<
    CreateUploadBucketSignedUrlsQuery,
    CreateUploadBucketSignedUrlsQueryVariables
  >({
    fetchPolicy: 'network-only',
    query: GQL_CREATE_UPLOAD_BUCKET_SIGNED_URLS,
    variables: {
      filePaths,
      // @ts-expect-error Need to deactivate apollo cache...
      id: generateHash(),
    },
  });
  const responses = await Promise.all(
    data.map(async file => {
      const errorReturn = {
        assetId: cuid(),
        url: '',
      };
      const formData = new FormData();
      const headers = new Headers();

      formData.append('file', file);
      headers.set('content-type', file.type);

      if (!urls) return errorReturn;

      const fileBlobAndAssetId = filesToUrlAndIdMap.get(file.name);
      if (!fileBlobAndAssetId) return errorReturn;

      const fileBlobPath = fileBlobAndAssetId.blobPath;
      if (!fileBlobPath) return errorReturn;

      const urlWithIdIndex = urls.findIndex(url => url.includes(fileBlobPath));
      if (urlWithIdIndex < 0) return errorReturn;

      const urlWithId = urls[urlWithIdIndex];
      if (!urlWithId) return errorReturn;

      const urlObject = new URL(urlWithId);

      urlObject.searchParams.delete('id');
      urlObject.searchParams.delete('bucketsigned');

      const urlToUseForUpload: string = urlObject.href;

      const isAzure = urlToUseForUpload.includes('blob.core.windows.net');
      if (isAzure) {
        headers.set('x-ms-blob-type', 'BlockBlob');
      }

      try {
        const response = await fetch(urlToUseForUpload, {
          body: file,
          headers,
          method: 'PUT',
        });

        if (response.status >= 300) return errorReturn;
        return { assetId: fileBlobAndAssetId.assetId, url: urlWithId };
      } catch (error) {
        console.error(error);
        return errorReturn;
      }
    }),
  );
  return responses;
};

export const getBase64AssetSizeInBytes = (base64String: string | null): number => {
  if (base64String == null) {
    return -1;
  }
  const stringSplit = base64String.split(',');
  const safeStringLength = stringSplit.length === 2 ? stringSplit[1].length : base64String.length;
  const sizeInBytes = 4 * Math.ceil(safeStringLength / 3) * 0.5624896334383812;
  const sizeInMb = sizeInBytes / 1e6;
  return sizeInMb;
};

const getBase64AssetSizeInMb = (base64String: string | null) => {
  const sizeInMb = getBase64AssetSizeInBytes(base64String) / NUMBER_OF_BYTES_IN_ONE_MB;
  return sizeInMb;
};

export const getMaxBatchSizeInNumberOfAssets = (contents: (string | null)[]): number => {
  const isImageUpload = contents.length > 0 && contents[0] && contents[0].startsWith('data:image');
  if (isImageUpload) {
    const assetSizesInMb = contents.map(getBase64AssetSizeInMb);
    const maxAssetSizesInMb = Math.max(...assetSizesInMb);
    if (maxAssetSizesInMb > 0) {
      const maxBatchSizeInNumberOfAssets = Math.floor(
        APPEND_MANY_TO_DATASET_MAX_BATCH_SIZE_IN_MB / maxAssetSizesInMb,
      );
      return maxBatchSizeInNumberOfAssets;
    }
  }
  return contents.length;
};

const isSimpleTextId = (id: string) => {
  return id.match(/^main\/\[\d+\]$/);
};

// This field is deprecated, but we keep it for compatibility with old annotations
const TEXT_BATCH_SIZE = 1_000;

export const getGlobalOffsetFromAnnotation = (id: string | undefined, offset: number): number => {
  const matches = id?.match?.(/\d+/);
  const index = matches && matches.length > 0 ? parseInt(matches[0], 10) : 0;
  const globalOffset = TEXT_BATCH_SIZE * index + offset;
  return globalOffset;
};

const cleanTextJsonResponse = (jobs: Jobs, jsonResponse: JsonResponse): JsonResponse => {
  const hasSimpleText = Object.keys(jsonResponse).reduce((acc, parentJobName) => {
    const response = jsonResponse[parentJobName] as JobAnnotation;
    return (
      response.annotations
        ?.map((an: Annotation) => {
          const casted = an as EntityAnnotation;
          if (casted.beginId && casted.endId) {
            return isSimpleTextId(casted.beginId) && isSimpleTextId(casted.endId);
          }
          return false;
        })
        .every(isSimpleText => isSimpleText) || acc
    );
  }, true);

  if (!hasSimpleText) return jsonResponse;

  const newJsonResponse = Object.keys(jsonResponse).reduce((acc, parentJobName) => {
    const response = jsonResponse[parentJobName] as JobAnnotation;
    return {
      ...acc,
      [parentJobName]: {
        ...response,
        annotations: response.annotations?.map((an: ObjectAnnotation) => {
          const casted = an as EntityAnnotation;
          if (
            casted.beginId &&
            casted.endId &&
            isSimpleTextId(casted.beginId) &&
            isSimpleTextId(casted.endId)
          ) {
            // convert annotation to new format
            const beginOffset = getGlobalOffsetFromAnnotation(casted.beginId, casted.beginOffset);
            const endOffset = beginOffset + casted.content.length;
            const { beginId, endId, ...otherProperties } = casted;
            return {
              ...otherProperties,
              beginOffset,
              endOffset,
            };
          }
          return an;
        }),
      } as JobAnnotation,
    };
  }, jsonResponse);

  return newJsonResponse;
};

const isAnnotationUnfinished = (annotation: Annotation, mlTask: MachineLearningTask) => {
  if (mlTask === MachineLearningTask.OBJECT_RELATION) {
    return (annotation as ObjectRelation)?.endObjects?.length === 0;
  }
  if (mlTask === MachineLearningTask.NAMED_ENTITIES_RELATION) {
    return (annotation as EntityRelation)?.endEntities?.length === 0;
  }
  return false;
};

export const cleanJsonResponseForAutosave = (
  inputType: InputType,
  jsonResponse: JsonResponse | FrameJsonResponse | undefined,
  jobs: Jobs,
): JsonResponse | FrameJsonResponse | undefined => {
  if (!jsonResponse) {
    return {};
  }
  if (inputType === InputType.VIDEO) {
    return jsonResponse as FrameJsonResponse;
  }

  const newJsonResponse = jsonResponse as JsonResponse;

  return Object.keys(newJsonResponse).reduce((acc, parentJobName) => {
    const response = newJsonResponse[parentJobName] as JobAnnotation;
    return {
      ...acc,
      [parentJobName]: {
        ...response,
        annotations: response.annotations?.filter(
          annotation => !isAnnotationUnfinished(annotation, jobs[parentJobName].mlTask),
        ),
      } as JobAnnotation,
    };
  }, newJsonResponse);
};

const convertToStandardJsonResponseClassic = (
  assetType: InputType,
  jobs: Jobs,
  jsonResponse: JsonResponse | undefined,
): JsonResponse | undefined => {
  if (!jsonResponse) {
    return {};
  }

  // eslint-disable-next-line prefer-const
  let { ANNOTATION_JOB_COUNTER, ANNOTATION_NAMES_JOB, ...newJsonResponse } = jsonResponse;

  // Here we need to convert the old format to the new format
  if (assetType === InputType.TEXT) {
    newJsonResponse = cleanTextJsonResponse(jobs, jsonResponse);
  }

  return Object.keys(newJsonResponse).reduce((acc, parentJobName) => {
    const response = newJsonResponse[parentJobName] as JobAnnotation;
    return {
      ...acc,
      [parentJobName]: {
        ...response,
        annotations: response.annotations?.map(
          ({ labelVersion, score, children, jobName, ...a }: ObjectAnnotation) => ({
            children: children ?? {},
            ...a,
          }),
        ),
      } as JobAnnotation,
    };
  }, newJsonResponse);
};

const convertToStandardJsonResponseFrame = (
  jobs: Jobs,
  frameJsonResponse: FrameJsonResponse | undefined,
): FrameJsonResponse | undefined => {
  if (!frameJsonResponse) {
    return {};
  }

  return Object.keys(frameJsonResponse).reduce((acc, frame) => {
    return {
      ...acc,
      [frame]: convertToStandardJsonResponseClassic(
        InputType.VIDEO,
        jobs,
        frameJsonResponse[frame],
      ),
    };
  }, {});
};

export const convertToStandardJsonResponse = (
  assetType: InputType,
  jobs: Jobs,
  jsonResponse: JsonResponse | FrameJsonResponse | undefined,
): JsonResponse | FrameJsonResponse | undefined => {
  return assetType === InputType.VIDEO
    ? convertToStandardJsonResponseFrame(jobs, jsonResponse as FrameJsonResponse)
    : convertToStandardJsonResponseClassic(assetType, jobs, jsonResponse as JsonResponse);
};

export const extractLabelsToDisplayInTable = (asset: Partial<Asset>, userId: string): Label[] => {
  const orderedLabels = _orderBy(asset?.labels, 'createdAt', 'desc');
  const orderedUniqLabels = _uniqBy(
    orderedLabels,
    label => `${label.author.id}+${label.labelType}`,
  );
  const orderUniqLabelsWithoutAutosaves = orderedUniqLabels.filter(
    (label: Label) =>
      label.labelType !== LabelType.AUTOSAVE ||
      (label.labelType === LabelType.AUTOSAVE && label.author.id === userId),
  );
  return orderUniqLabelsWithoutAutosaves;
};

export const isGeoSpatialContent = (jsonContent?: JsonContent): jsonContent is GeoSpatialContent =>
  !!jsonContent && Array.isArray(jsonContent) && 'epsg' in jsonContent[0];
