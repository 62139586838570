import { gql } from '@apollo/client';

export const SIMPLE_LABEL_FRAGMENTS = gql`
  fragment LabelWithAssetInfos on Label {
    clientVersion
    jsonResponse
    referencedLabel {
      id
    }
  }
`;

export const ASSET_FRAGMENTS = gql`
  fragment AssetFragment on Asset {
    id
    consensusMark
    content
    createdAt
    duration
    dynamicTilerTokens
    externalId
    honeypotMark
    inferenceMark
    isHoneypot
    isProcessingAuthorized
    isUsedForConsensus
    issues {
      id
      assigneeIdUser
      authorIdUser
      status
      type
    }
    jsonContent
    jsonMetadata
    labels {
      ...LabelInfos
    }
    ocrMetadata
    priority
    skipped
    status
    thumbnail
    updatedAt
    toBeLabeledBy {
      ...AssigneeInfos
    }
    currentStep {
      id
      type
      name
      status
    }
  }

  fragment LabelInfos on Label {
    id
    author {
      id
      email
      firstname
      lastname
    }
    clientVersion
    createdAt
    honeypotMark
    isHoneypot
    isLatestLabelForUser
    isSentBackToQueue
    labelType
    modelName
    numberOfAnnotations
    reviewScore
    totalSecondsToLabel
    assetStep {
      id
      status
      stepId
    }
  }

  fragment AssigneeInfos on ProjectUser {
    id
    user {
      id
      firstname
      lastname
      email
    }
  }
`;
