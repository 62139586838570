import { type JsonResponse } from '@kili-technology/types';
import type * as H from 'history';
import _get from 'lodash/get';
import { generatePath } from 'react-router-dom';

import { InputType } from '@/__generated__/globalTypes';
import { projectInputType } from '@/redux/selectors';
import { store } from '@/store';

import { rootRoutes } from '../../pages/RootModule/RootPaths';
import { getQueueUrl } from '../../pages/projects/ProjectMenu/routes';
import { type LabelPartial } from '../../redux/asset-label/types';

export type PushRoute = (path: H.History.Path) => void;

export const parseLabelResponse = (
  data: LabelPartial | null | void,
): LabelPartial & { jsonResponse: JsonResponse } => {
  return { ...data, jsonResponse: JSON.parse(_get(data, 'jsonResponse', '{}')) };
};

export const goToNextRoute = ({
  assetId,
  inReview,
  labelID,
  projectId,
  pushRoute,
  userID,
}: {
  assetId: string | undefined;
  inReview: boolean;
  labelID?: string;
  projectId: string;
  pushRoute: PushRoute;
  userID: string;
}): void => {
  const inputType = projectInputType(store.getState());

  if (assetId) {
    const route =
      labelID && inReview ? `?userID=${userID}&labelID=${labelID}` : `?userID=${userID}`;
    if (inReview) {
      if (inputType === InputType.LLM_INSTR_FOLLOWING || inputType === InputType.LLM_STATIC)
        pushRoute(
          `${generatePath(rootRoutes.ROOT_REVIEW_INSTR_FOLLOWING_ROUTE.path, {
            assetID: assetId,
          })}${route}`,
        );
      else
        pushRoute(
          `${generatePath(rootRoutes.ROOT_REVIEW_ROUTE.path, { assetID: assetId })}${route}`,
        );
    } else if (inputType === InputType.LLM_INSTR_FOLLOWING || inputType === InputType.LLM_STATIC) {
      pushRoute(
        `${generatePath(rootRoutes.ROOT_LABEL_INSTR_FOLLOWING_ROUTE.path, {
          assetID: assetId,
        })}${route}`,
      );
    } else {
      pushRoute(`${generatePath(rootRoutes.ROOT_LABEL_ROUTE.path, { assetID: assetId })}${route}`);
    }
  } else {
    goToProjectQueue({ projectId, pushRoute });
  }
};

export const goToProjectQueue = ({
  projectId,
  pushRoute,
}: {
  projectId: string;
  pushRoute: PushRoute;
}): void => {
  pushRoute(getQueueUrl(projectId));
};
