import { type GetAnnotationsQuery } from '@/graphql/annotations/__generated__/queries.graphql';
import { GQL_GET_ANNOTATIONS } from '@/graphql/annotations/queries';
import { type Annotation } from '@/graphql/annotations/types';
import { getApolloClient } from '@/graphql/helpers';
import { getLabelId } from '@/redux/asset-label/helpers/getLabelId';

export function getAnnotationsFromCache(): Annotation[] {
  const apolloClient = getApolloClient();
  const labelId = getLabelId();
  let getAnnotationsQueryResult: GetAnnotationsQuery | null | undefined;

  try {
    getAnnotationsQueryResult = apolloClient.cache.readQuery<GetAnnotationsQuery>({
      query: GQL_GET_ANNOTATIONS,
      variables: { where: { labelId } },
    });
  } catch {
    // Query not in cache
  }

  return getAnnotationsQueryResult?.annotations ?? [];
}
