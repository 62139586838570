import { type Jobs, MachineLearningTask, Tool } from '@kili-technology/types';
import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import _uniq from 'lodash/uniq';
import { createSelector } from 'reselect';

import { computeIsProjectUsingOrientedTools, computePossibleLabelFormats } from './helpers';
import buildOntologyTree, { buildFlatOntology } from './ontologyTree';
import buildObjectCategoryToPossibleRelationCategories from './relations';
import { type ProjectState } from './types';

import {
  type Asset,
  type ComplianceTag,
  InputType,
  type Project,
  type Right,
} from '../../__generated__/globalTypes';
import { ML_TASK, TOOLS } from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { DEFAULT_VALUE } from '../labels/selectors';
import { projectInputType, projectJobs, projectJsonInterface } from '../selectors';
import { type State } from '../types';
import { type UserState } from '../user/types';

const projectDataset = (state: State): Partial<Asset>[] => _get(state, 'assets', []);

export const projectDatasetFiles = (state: State): File[] => {
  if (!state.project) {
    return [];
  }
  const dataset = projectDataset(state);
  return dataset
    .filter(item => item && item !== null)
    .map(item => {
      return new File([_get(item, 'content', '')], _get(item, 'filename', ''), {
        type: item.id,
      });
    });
};

export const projectInstructions = (state: State): string =>
  _get(state, 'project.instructions') || '';

export const projectUseConsensus = (state: State): boolean =>
  _get(state, 'project.consensusTotCoverage', 0) > 0;

export const projectUseHoneyPot = (state: State): boolean =>
  _get(state, 'project.useHoneyPot', false);

export const projectAuthor = (state: State): UserState => _get(state, 'project.author', '');

export const projectAuthorOrganization = (state: State): string =>
  _get(state, 'project.author.organization.id', '');

export const projectCreatedAt = (state: State): Project['createdAt'] =>
  _get(state, 'project.createdAt', '');

export const projectDescription = (state: State): string => _get(state, 'project.description', '');

export const projectID = (state: State): string => _get(state, 'project.id', '');

export const isProjectArchivedSelector = (state: State): boolean =>
  !!_get(state, 'project.archivedAt', null);

export const isProjectAnonymizedSelector = (state: State): boolean =>
  state.project?.isAnonymized ?? false;

export const complianceTagsSelector = (state: State): Array<ComplianceTag | null> =>
  state.project.complianceTags ?? [];

export const projectJsonInterfaceMlTasks = createSelector([projectJobs], jobs => {
  return Object.values(jobs).map(job => _get(job, ML_TASK, ''));
});

const projectTree = createSelector([projectJobs], jobs => buildOntologyTree(jobs));
export const projectTreeFlat = createSelector([projectTree], tree => buildFlatOntology(tree));

const projectDetectionJobs = createSelector([projectJobs], jobs =>
  _pickBy(jobs, job =>
    [MachineLearningTask.OBJECT_DETECTION, MachineLearningTask.NAMED_ENTITIES_RECOGNITION].includes(
      job.mlTask,
    ),
  ),
);

const projectRelationJobs = createSelector([projectJobs], jobs =>
  _pickBy(jobs, job =>
    [MachineLearningTask.OBJECT_RELATION, MachineLearningTask.NAMED_ENTITIES_RELATION].includes(
      job.mlTask,
    ),
  ),
);

const projectDetectionCategories = createSelector([projectDetectionJobs], jobs =>
  _uniq(
    Object.values(jobs)
      .map(job => Object.keys(job?.content?.categories ?? {}))
      .flat(),
  ),
);

export const projectObjectCategoriesToRelationCategoriesMember = createSelector(
  [projectDetectionCategories, projectRelationJobs],
  (detectionCategories, relationJobs) =>
    buildObjectCategoryToPossibleRelationCategories(detectionCategories, relationJobs),
);

export const projectJsonInterfaceTools = createSelector([projectJobs], jobs => {
  const jobToolsWithRepetition = Object.values(jobs)
    .map(job => _get(job, TOOLS, []))
    .flat();
  return _uniq(jobToolsWithRepetition);
});

export const projectCompatibleJobsWithChatGptPreAnnotation = createSelector(
  [projectJobs, projectInputType],
  (jobs, inputType): string[] | undefined => {
    if (inputType === InputType.TEXT) {
      const compatibleJobs = Object.entries(jobs).reduce<string[]>((acc, [jobName, job]) => {
        if (
          !job?.isChild &&
          (job?.mlTask === MachineLearningTask.CLASSIFICATION ||
            job?.mlTask === MachineLearningTask.NAMED_ENTITIES_RECOGNITION)
        ) {
          acc.push(jobName);
        }
        return acc;
      }, []);

      if (compatibleJobs.length > 0) {
        return compatibleJobs;
      }
    }

    return undefined;
  },
);

export const projectCompatibleJobsWithOWLv2PreAnnotation = createSelector(
  [projectJobs, projectInputType],
  (jobs, inputType): string[] | undefined => {
    if ([InputType.GEOSPATIAL, InputType.IMAGE].includes(inputType)) {
      const compatibleJobs = Object.entries(jobs).reduce<string[]>((acc, [jobName, job]) => {
        if (
          !job?.isChild &&
          job?.mlTask === MachineLearningTask.OBJECT_DETECTION &&
          (job?.tools ?? []).includes(Tool.RECTANGLE)
        ) {
          acc.push(jobName);
        }
        return acc;
      }, []);

      if (compatibleJobs.length > 0) {
        return compatibleJobs;
      }
    }

    return undefined;
  },
);

export const projectTitle = (state: State): string => _get(state, 'project.title', '');

export const projectState = (state: State): ProjectState => _get(state, 'project');

export const projectNumberOfRemainingAssets = createSelector([projectState], project => {
  const numberOfRemainingAssets = _get(project, 'numberOfRemainingAssets', DEFAULT_VALUE);
  if (numberOfRemainingAssets === null) {
    return DEFAULT_VALUE;
  }
  return numberOfRemainingAssets;
});

export const projectRights = (state: State): Right[] =>
  _get(state, 'project.rights', [] as Right[]);

export const projectSpeechToTextJobName = createSelector(
  [projectJobs],
  jobs =>
    Object.entries(jobs).find(
      ([_, job]) => job?.mlTask === MachineLearningTask.SPEECH_TO_TEXT,
    )?.[0] ?? '',
);

export const projectPossibleLabelFormats = (hasDataConnections?: boolean) =>
  createSelector(
    [projectInputType, projectJsonInterface],
    computePossibleLabelFormats(hasDataConnections),
  );

export const projectUseOrientedTools = createSelector(
  [projectJsonInterface],
  computeIsProjectUsingOrientedTools,
);

export const getJobNamesWithoutInstructionGroupedByMlTask = (
  jobs: Jobs,
): Record<MachineLearningTask, string[]> =>
  Object.keys(jobs).reduce((acc, jobName) => {
    const { mlTask } = jobs[jobName];
    if (!jobs[jobName].instruction) return { ...acc, [mlTask]: [...(acc[mlTask] || []), jobName] };
    return acc;
  }, {} as Record<MachineLearningTask, string[]>);

export const jobsNameWithoutInstructionGroupedByMlTask = createSelector([projectJobs], jobs =>
  getJobNamesWithoutInstructionGroupedByMlTask(jobs),
);

export const projectNotNestedClassificationJobs = createSelector(
  [projectJsonInterface],
  jsonInterface =>
    Object.fromEntries(
      Object.entries(jsonInterface.jobs || {}).filter(
        ([_, job]) => !job.isChild && job.mlTask === MachineLearningTask.CLASSIFICATION,
      ),
    ),
);

export const shouldEnableBatchClassificationInProject = createSelector(
  [projectJsonInterface, projectNotNestedClassificationJobs, projectInputType],
  (jsonInterface, notNestedClassificationJobs, inputType) => {
    return (
      [InputType.GEOSPATIAL, InputType.IMAGE, InputType.TEXT].includes(inputType) &&
      notNestedClassificationJobs &&
      Object.keys(notNestedClassificationJobs).length > 0 &&
      Object.values(jsonInterface.jobs || {}).every(
        job => !job.required || (!job.isChild && job.mlTask === MachineLearningTask.CLASSIFICATION),
      )
    );
  },
);
