/* eslint-disable no-underscore-dangle */
import L from 'leaflet';

const originalInitHandlers = L.Edit.Poly.prototype._initHandlers;

L.Edit.Poly.include({
  _initHandlers() {
    this.latlngs = [this._poly._latlngs];
    if (this._poly._holes) {
      this.latlngs = this.latlngs.concat(this._poly._holes);
    }
    originalInitHandlers.call(this);
  },
});
