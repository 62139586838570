import { Close } from '@kili-technology/icons';
import { IconButton, Link } from '@material-ui/core';
import React from 'react';

import { useStore } from '@/zustand';

import { classes } from './BannerLimitReached.style';

export const BannerLimitReached: React.FC = () => {
  const { isLicenseLimitBannerOpen, setLicenseLimitBannerOpen } = useStore(state => state.topBar);

  if (!isLicenseLimitBannerOpen) {
    return null;
  }

  const handleCloseBanner = () => {
    setLicenseLimitBannerOpen(false);
  };

  return (
    <div className={classes.container} data-cy="banner-limit-reached">
      <p className={classes.text}>
        You&apos;ve tried to use a feature that is not included in your plan, or your organization
        has reached its usage limit. Please contact your admin or{' '}
        <Link href="https://kili-technology.com/pricing" rel="noopener noreferrer" target="_blank">
          reach out to our sales team
        </Link>{' '}
        to explore upgrade options.
      </p>
      <IconButton
        className={classes.closeButton}
        color="inherit"
        onClick={handleCloseBanner}
        size="small"
      >
        <Close />
      </IconButton>
    </div>
  );
};
