import { type TopBarSlice, type TopBarSliceValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: TopBarSliceValues = {
  isIssuesPanelOpen: false,
  isLicenseLimitBannerOpen: false,
  isShortcutsPanelOpen: false,
};

const log = createLogger('topBar');

export const createTopBarSlice: ZustandSlice<TopBarSlice> = set => ({
  ...initialState,
  clickOnIssuesButton: () =>
    set(
      state => {
        const { isIssuesPanelOpen, isShortcutsPanelOpen } = state.topBar;
        state.topBar.isIssuesPanelOpen = !isIssuesPanelOpen;
        if (isShortcutsPanelOpen) {
          state.topBar.isShortcutsPanelOpen = false;
        }
      },
      false,
      log('clickOnIssuesButton'),
    ),
  clickOnShortcutsButton: () =>
    set(
      state => {
        const { isIssuesPanelOpen, isShortcutsPanelOpen } = state.topBar;
        state.topBar.isShortcutsPanelOpen = !isShortcutsPanelOpen;
        if (isIssuesPanelOpen) {
          state.topBar.isIssuesPanelOpen = false;
        }
      },
      false,
      log('clickOnShortcutsButton'),
    ),
  initialize: () =>
    set(
      state => {
        Object.entries(initialState).forEach(([key, value]) => {
          const keyInitialState = key as keyof typeof initialState;
          state.topBar[keyInitialState] = value;
        });
      },
      false,
      log('initialize'),
    ),
  setIssuesPanelOpen: (newIsIssuesPanelOpen: boolean) =>
    set(
      state => {
        state.topBar.isIssuesPanelOpen = newIsIssuesPanelOpen;
      },
      false,
      log('setIssuesPanelOpen'),
    ),
  setLicenseLimitBannerOpen: (isVisible: boolean) =>
    set(
      state => {
        state.topBar.isLicenseLimitBannerOpen = isVisible;
      },
      false,
      log('setLicenseLimitBannerOpen'),
    ),
});

export * from './types';
