import { InputType } from '@/__generated__/globalTypes';
import { projectJsonInterface } from '@/redux/selectors';
import { store } from '@/store';

export const areObjectsLocked = () => {
  const storeState = store.getState();
  const { inputType } = storeState.project;
  if (
    inputType !== InputType.IMAGE &&
    inputType !== InputType.PDF &&
    inputType !== InputType.GEOSPATIAL
  )
    return false;

  const jsonInterface = projectJsonInterface(storeState);
  return jsonInterface.lockObjects === true;
};
