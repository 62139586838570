import { gql } from '@apollo/client';

export const GQL_GET_LABELS_TO_BE_REVIEWED = gql`
  query GetLabelsToBeReviewed($assetID: ID!, $labelID: ID) {
    getLabelsToBeReviewed(assetID: $assetID, labelID: $labelID) {
      id
      author {
        email
        id
        firstname
        lastname
      }
      jsonResponse
      labelType
      clientVersion
      referencedLabel {
        id
      }
      assetStep {
        id
        status
        stepId
      }
    }
  }
`;
