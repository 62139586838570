import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import _get from 'lodash/get';

import { AUTH0_ACCESS_TOKEN_FOR_CYPRESS } from './auth0';
import {
  getGraphqlEndpoint,
  getWebSocketEndpoint,
  isDevelopmentEnvironment,
  isTestingOldSignInOnCypress,
  onCypress,
} from './config';
import {
  ACCESS_DENIED,
  DEACTIVATED_USER,
  DUPLICATED_EXTERNAL_ID_ERROR,
  JSON_MALFORMED,
  LICENSE_NUMBER_OF_LABELED_ASSETS,
  MAX_PROJECT_SIZE_ERROR,
  MAXIMUM_NUMBER_OF_PROJECT_USERS,
  NO_ACCESS_RIGHT_ERROR,
  NO_RESULT,
  PASSWORD_SHOULD_MATCH_ERROR,
  UNEXPECTED_RETRIEVING,
  WRONG_PASSWORD,
  USER_SUSPENDED_IN_ORGANIZATION,
  USER_SUSPENDING_SELF,
  COPILOT_MAX_CALLS,
  ISSUE_LABEL_AUTHOR_REMOVED,
  INVALID_OPERATION,
  ASSET_ALREADY_LOCKED,
} from './constants/backendErrors';
import { sendErrorToDatadog, sendToDatadog } from './datadog';
import getKiliErrorCode from './helpers/getKiliErrorCode';
import { rootRoutes } from './pages/RootModule/RootPaths';
import appHistory from './pages/RootModule/history';
import {
  setAuthenticationToken,
  activateIsLoading,
  addErrorNotification,
  deactivateIsLoading,
} from './redux/application/actions';
import { authenticationToken } from './redux/authentication/selectors';
import { shouldAnonymizeUser } from './redux/project-user/helpers';
import { store } from './store';

import generatedIntrospection from '../introspection-fragment-plugin';

const apiEndpointV2 = getGraphqlEndpoint();
const wsEndpoint = getWebSocketEndpoint();

// List of discarded errors
const errorsWhiteList = [
  'Cannot query field "review" on type "Asset".',
  'Duplicated externalID, please insert unique externalIDs.',
  'Incorrect password',
  'Invalid password',
  'No such user found',
  '[labelExpired]',
  ACCESS_DENIED,
  DUPLICATED_EXTERNAL_ID_ERROR,
  NO_ACCESS_RIGHT_ERROR,
  NO_RESULT,
  PASSWORD_SHOULD_MATCH_ERROR,
  UNEXPECTED_RETRIEVING,
  WRONG_PASSWORD,
  ASSET_ALREADY_LOCKED,
];

// List of errors directly transmitted to frontend
const errorsByPassList = [
  COPILOT_MAX_CALLS,
  DEACTIVATED_USER,
  INVALID_OPERATION,
  ISSUE_LABEL_AUTHOR_REMOVED,
  JSON_MALFORMED,
  LICENSE_NUMBER_OF_LABELED_ASSETS,
  MAX_PROJECT_SIZE_ERROR,
  MAXIMUM_NUMBER_OF_PROJECT_USERS,
  USER_SUSPENDED_IN_ORGANIZATION,
  USER_SUSPENDING_SELF,
];

const getAuthorizationToken = accessToken => {
  const token =
    onCypress && !isTestingOldSignInOnCypress
      ? localStorage.getItem(AUTH0_ACCESS_TOKEN_FOR_CYPRESS) || accessToken
      : accessToken || authenticationToken(store.getState());
  const prefix = 'Bearer';
  return token ? `${prefix}: ${token}` : '';
};

function canRemoveError(graphQLErrors) {
  let canRemove = false;
  if (!graphQLErrors) {
    return canRemove;
  }
  graphQLErrors.forEach(error => {
    const whiteList = [
      error?.path?.length === 4 && error.path[1] === 'dataset' && error.path[3] === 'review',
      error?.path?.length === 1 && error.path[0] === 'appendManyToDatasetFrontend',
      error.message &&
        errorsWhiteList.filter(errorCode => error.message.includes(errorCode)).length > 0,
    ];
    const isWhiteListed = whiteList.some(bool => bool);

    if (isWhiteListed) {
      canRemove = true;
    }
  });
  return canRemove;
}

export const cacheProps = {
  possibleTypes: generatedIntrospection.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        apiKeys: {
          merge(existing, incoming) {
            const merged = existing ? existing.slice(0) : [];
            const existingIndexes = merged.reduce((acc, { __ref }, index) => {
              acc[__ref] = index;
              return acc;
            }, {});
            for (let i = 0; i < incoming.length; i += 1) {
              const incomingRef = incoming[i].__ref;
              if (typeof existingIndexes[incomingRef] === 'undefined') {
                merged.push(incoming[i]);
              } else {
                merged[existingIndexes[incomingRef]] = incoming[i];
              }
            }

            return merged;
          },
        },
        issues: {
          merge(existing, incoming) {
            const merged = existing ? existing.slice(0) : [];
            const existingIndexes = merged.reduce((acc, { __ref }, index) => {
              acc[__ref] = index;
              return acc;
            }, {});
            for (let i = 0; i < incoming.length; i += 1) {
              const incomingRef = incoming[i].__ref;
              if (typeof existingIndexes[incomingRef] === 'undefined') {
                merged.push(incoming[i]);
              } else {
                merged[existingIndexes[incomingRef]] = incoming[i];
              }
            }

            return merged;
          },
          read(existing) {
            return existing;
          },
        },
      },
    },
    User: {
      fields: {
        anonymizedEmail: {
          read(_, { readField }) {
            const email = readField('email');
            if (!email) return '';

            const userId = readField('id');
            return shouldAnonymizeUser(userId) ? 'Anonymized' : email;
          },
        },
        firstname: {
          read(firstname, { readField }) {
            if (firstname === undefined) return firstname;
            const userId = readField('id');
            return shouldAnonymizeUser(userId) ? 'Anonymized' : firstname;
          },
        },
        lastname: {
          read(lastname, { readField }) {
            if (lastname === undefined) return lastname;
            const userId = readField('id');
            return shouldAnonymizeUser(userId) ? '' : lastname;
          },
        },
      },
    },
  },
};
export const cache = new InMemoryCache(cacheProps);

async function initializeGraphQLClient(accessToken, dispatch, handle401, handleGraphQLErrors) {
  const routePathRegister = rootRoutes.ROOT_REGISTER_ROUTE.path;
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(graphQLError => {
        const message = _get(graphQLError, 'message');
        const isKiliErrorMessage = /\[.*?\]/.test(message);
        if (message && isKiliErrorMessage) {
          const kiliErrorCode = getKiliErrorCode(graphQLError);
          if (kiliErrorCode === 'emailNotVerified') {
            appHistory.push(routePathRegister);
          }
        }
      });
    }
    if (!!networkError && networkError.statusCode === 401) {
      handle401();
    } else {
      (graphQLErrors || []).forEach(graphQLError => {
        const message = _get(graphQLError, 'message');
        sendErrorToDatadog(graphQLError, { message });
      });
      if (!canRemoveError(graphQLErrors) && graphQLErrors) {
        graphQLErrors.forEach(graphqlError => {
          const message = _get(graphqlError, 'message');
          if (
            message &&
            errorsByPassList.filter(errorCode => message.includes(errorCode)).length > 0
          ) {
            const errorCode = errorsByPassList.filter(code => message.includes(code))[0];
            store.dispatch(
              addErrorNotification(message.replace(`[${errorCode}] `, '').split('--')[0]),
            );
          } else {
            handleGraphQLErrors(graphqlError);
          }
          sendToDatadog(graphqlError, null, 'graphql');
        });
      }
      if (networkError && networkError.name !== 'AbortError') {
        sendToDatadog(networkError, null, 'network');
      }
    }
  });
  const retryLink = new RetryLink({ attempts: { retryIf: error => error.statusCode !== 555 } });

  const httpLink = new HttpLink({
    uri: apiEndpointV2,
  });

  const authorization = getAuthorizationToken(accessToken);
  dispatch(setAuthenticationToken({ token: authorization }));

  const wsLink = new GraphQLWsLink(
    createClient({
      connectionParams: {
        Authorization: authorization,
      },
      url: wsEndpoint,
    }),
  );

  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );
  const authLink = setContext((_, previousContext) => {
    const { headers } = previousContext;
    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  });
  const defaultOptions = {
    mutate: {
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      errorPolicy: 'ignore',
      fetchPolicy: 'cache-and-network',
    },
  };

  return new ApolloClient({
    assumeImmutableResults: true,
    cache,
    connectToDevTools: !!isDevelopmentEnvironment(),
    defaultOptions,
    link: ApolloLink.from([errorLink, retryLink, authLink.concat(link)]),
    name: 'label-frontend',
    version: _get(window, '_env_.KILI__VERSION'),
  });
}

export const clientQuery = async ({
  actionId,
  client,
  clientName,
  dispatch,
  query,
  variables,
  withLoader,
  fetchPolicy = 'no-cache',
}) => {
  if (withLoader && dispatch) dispatch(activateIsLoading());
  const queryReturn = await client
    .query({
      context: {
        clientName,
        headers: {
          actionId,
        },
      },
      fetchPolicy,
      query,
      variables,
    })

    .catch(error => {
      if (error.message.indexOf(NO_RESULT) !== -1) return { data: null };
    });
  if (withLoader && dispatch) dispatch(deactivateIsLoading());
  return queryReturn;
};

export const clientMutate = async ({
  client,
  clientName,
  dispatch,
  mutation,
  variables,
  withLoader,
}) => {
  if (withLoader) dispatch(activateIsLoading());
  const mutationReturn = await client.mutate({
    context: { clientName },
    mutation,
    variables,
  });
  if (withLoader) dispatch(deactivateIsLoading());
  return mutationReturn;
};

export default initializeGraphQLClient;
